//middleware
import { Route } from "react-router-dom";

//views
import { ROUTES } from "../utils/routes";
import { Commissions } from "../views/Logged/Commissions/Commissions";
import { ManageCommission } from "../views/Logged/Commissions/ManageCommission/ManageCommission";
import { Announcements } from "../views/Logged/Announcements/Announcements";
import { ManageAnnouncements } from "../views/Logged/Announcements/ManageAnnouncements/ManageAnnouncements";
import { Intranets } from "../views/Logged/Intranet/Intranet";
import { ManageNews } from "../views/Logged/News/ManageNews/ManageNews";
import { News } from "../views/Logged/News/News";

const Phds = () => {
  // prettier-ignore
  return (
    <>
      <Route exact path={ROUTES.Commissions.all} component={Commissions} />
      <Route
        exact
        path={[ROUTES.Commissions.create, ROUTES.Commissions.update]}
        component={ManageCommission}
      />
      
            <Route exact path={ROUTES.News.all} component={News} />
            <Route
              exact
              path={[ROUTES.News.create, ROUTES.News.update]}
              component={ManageNews}
            />

      <Route exact path={ROUTES.Announcements.all} component={Announcements} />
      <Route
        exact
        path={[ROUTES.Announcements.create, ROUTES.Announcements.update]}
        component={ManageAnnouncements}
      />
      <Route exact path={ROUTES.Intranet.all} component={Intranets} />
     
    </>
  );
};

export default Phds;
