import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

//datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//middelware
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FileUploader } from "../../../../components/FileUploader/FileUploader";
import Axios from "axios";
import moment from "moment";

//ckeditor
import {CKEditor} from "ckeditor4-react";
import { editorConfig, SwalError } from "../../../../utils/constants";

import {
  create,
  getAll,
  getById,
  saveFile,
  update,
} from "../../../../utils/requests/dynamicReq";
import { ROUTES } from "../../../../utils/routes";
import Swal from "sweetalert2";
import { paths } from "../../../../utils/paths";
import { useDelete } from "../../../../utils/hooks/useDelete";
import { validate } from "./validations";

export const ManageNews = () => {
  const { COMMUNICATIONS, COMMTYPES, PROJECTS } = paths;
  const { id } = useParams();
  const { deleteIt } = useDelete();

  let { staffId, isAdmin, userId } = useSelector(({ user }) => user);

  const history = useHistory();

  const [isCreate, setIsCreate] = useState(false);
  const [youtubeLink, setYoutubeLink] = useState();
  const [tag, setTag] = useState("research");
   const [borrador, setBorrador] = useState('1')
   const [subcategory, setSubCategory] = useState("other");	
  const [titleEs, setTitleEs] = useState("");
  const [titleEn, setTitleEn] = useState("");
   const [titleVa, setTitleVa] = useState('');
  const [contentEs, setContentEs] = useState("");
  const [contentEn, setContentEn] = useState("");
  const [contentVa, setContentVa] = useState('');
  const [keywordsEs, setKeywordsEs] = useState('');
  const [keywordsEn, setKeywordsEn] = useState('');
  const [keywordsVa, setKeywordsVa] = useState('');
  const [communicationTypes, setCommunicationTypes] = useState([]);
  const [chosenCommunicationTypeId, setChosenCommunicationTypeId] = useState("");
 
  const [creatorId, setCreatorId] = useState(null);
  const [date, setDate] = useState(new Date());
  const [image, setImage] = useState();
  const [message, setMessage] = useState("");
const [allowedEdit, setAllowedEdit] = useState(false);

 const [eventVenue, setEventVenue] = useState('');
 const [eventDateTime, setEventDateTime] = useState('');
 const [eventPresenter, setEventPresenter] = useState('');
 const [eventTitle, setEventTitle] = useState('')

  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();

  useEffect(() => {
    isMountedRef.current = true;
    const { pathname } = history.location;
    pathname.includes("crear") ? setIsCreate(true) : loadData();
    loadStaticData();
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadStaticData = async () => {
    try {
      let communicationTypes = await getAll(COMMTYPES, source);
      isMountedRef.current && setCommunicationTypes(communicationTypes);  
      } catch (error) {
      console.log("HTTP call cancelled");
    }
  };

  const loadData = async () => {
    try {
      let response = await getById(COMMUNICATIONS, id, source);    
      isMountedRef.current && buildObjToLoad(response);
    } catch (error) {
      console.log("HTTP call cancelled");
    }
  };

  // prettier-ignore
  const buildObjToLoad = (response) => {
    const {
			titleEn,
			titleEs,
			titleVa,
			contentEn,
			contentEs,
			contentVa,
			youtubeLink,
			tag,
			borrador,
			subcategory,
			image,
			keywordsEs,
			keywordsEn,
			keywordsVa,
			date,
			eventDateTime,
			eventPresenter,
			eventVenue,
			eventTitle
		} = response;
    const {user: { id: userId }} = response;
    const {communicationType: { id: typeId },} = response;
	const dateWithOffset = new Date(date);
	const dateNoOffset = ( new Date( dateWithOffset.getTime() - dateWithOffset.getTimezoneOffset() * -60000 ));


    setChosenCommunicationTypeId(typeId);
    setTitleEn(titleEn);
    setTitleEs(titleEs);
	setTitleVa(titleVa);
    setYoutubeLink(youtubeLink);
    setTag(tag)
    setBorrador(borrador ? 1 : 0)
    setSubCategory(subcategory)
    setCreatorId(userId);
    setImage(image);
    setKeywordsEs(keywordsEs);
	setKeywordsEn(keywordsEn);
	setKeywordsVa(keywordsVa);
    setDate(dateNoOffset);
	setContentEs(contentEs);
	setContentEn(contentEn);
	setContentVa(contentVa);
	setEventDateTime(moment(eventDateTime).format('YYYY-MM-DDTHH:mm:SS'));
	setEventPresenter(eventPresenter)
	setEventVenue(eventVenue)
	setEventTitle(eventTitle)

	if (isMountedRef.current) 
	setAllowedEdit(true);
  };

  const handleSubmit = () => {
    const news = build();    
    const notValid = validate(news);
    if (notValid) {
      setMessage(notValid);
      setTimeout(() => setMessage(""), 4000);
      return;
    }
    let question = isCreate ? "crear una" : "editar esta";
    Swal.fire({
      icon: "question",
      text: `¿Quieres ${question} notica?`,
      showCancelButton: true,
      confirmButtonText: "Si", 
      cancelButtonText: "No",
    }).then(({ isConfirmed }) => {
      isConfirmed && handleConfirmation(news);
    });
  };

  const handleConfirmation = (news) => {
    let createOrUpdate = isCreate
      ? create(COMMUNICATIONS, news, source)
      : update(COMMUNICATIONS, id, news, source);
    createOrUpdate
      .then(async ({ id }) => {
        Swal.fire({
          icon: "success",
          text: `Noticia ${isCreate ? "creada" : "editada"} correctamente. Será enviada para su revisión`,
        });
        if (typeof image !== "string") {
          const formData = new FormData();
          formData.append("image", image);
          await saveFile(COMMUNICATIONS, id, "image", formData, source);
        }
        history.push(`${ROUTES.News.all}/${id}`);
        setIsCreate(false);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          text: SwalError,
        });
      });
  };

const build = () => {
	// Ajusta la fecha y hora para la zona horaria requerida, si es necesario
	const adjustedEventDateTime = moment(eventDateTime)
		.utcOffset('+00:00')
		.format('YYYY/MM/DD HH:mm:ss');

	return {
		userId: creatorId ? creatorId : userId,
		titleEs,
		titleEn,
		titleVa,
		date: moment(date).format('YYYY-MM-DD'),
		youtubeLink,
		tag,
		borrador: borrador === '1' || borrador === 1 ? true : false,
		subcategory,
		eventDateTime: adjustedEventDateTime === 'Invalid date' ? null : adjustedEventDateTime, // Usa la fecha y hora ajustada y formateada
		eventPresenter,
		eventVenue,
		eventTitle,
		contentEs,
		contentEn,
		contentVa,
		keywordsEs,
		keywordsEn,
		keywordsVa,
		communicationTypeId: chosenCommunicationTypeId
			? chosenCommunicationTypeId
			: null
	};
};




  const editDeleteButtons = () => {
    if (isCreate) {
      return (
        <button className="baseBtn" onClick={handleSubmit}>
          Crear Noticia
        </button>
      );
    }
    if ((!isCreate && userId === creatorId) || isAdmin)  { 
      return (
        <>
          <button className="baseBtn" onClick={handleSubmit}>
            Editar Noticia
          </button>
          <button
            className="smallBtn btn-danger ml-1"
            onClick={() => deleteIt(COMMUNICATIONS, id, ROUTES.News.all, source)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </>
      );
    }
  };

  const cleanSubcategory = ()=>{

	setSubCategory("other")

  }

  return (
    <div className="centerPage">
      <div className="container-fluid p-4">
        <div className="row">
          <div className="col d-flex justify-content-between">
            <div>
              <h3>Noticia </h3>
            </div>
            <div>{editDeleteButtons()}</div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 text-center text-danger">
            <span>{message}</span>
          </div>
        </div>
        <div className="form-row mt-2">
          <div className="col-6 d-flex  justify-content-center">
            <FileUploader handleFile={setImage} image={image} />
          </div>
     {    isAdmin && <div className="col-6">
            <small>*Estado - </small>
		<small>(Las noticias en revision no seran visibles en la web)</small>
            <select
              className="form-control input"
              value={borrador}
              onChange={({ target: { value } }) => {
                setBorrador(value);
              }}
            >
              <option value={'1'}>En revisión</option>
              <option value={'0'}>Aprobado</option>
            </select>
          </div>}
          <div className="col-6  d-flex flex-column  justify-content-center">
            <small>Enlace a video (Youtube)</small>
            <input
              type="text"
              className="form-control"
              placeholder="Enlace a video (Youtube)"
              value={youtubeLink}
              onChange={({ target: { value } }) => {
                setYoutubeLink(value);
              }}
            />
          </div>
        </div>

        <div className="form-row mt-2">
          <div className="col">
            <small>*Título español</small>
            <input
              type="text"
              className="form-control"
              placeholder="*Título español"
              value={titleEs}
              onChange={({ target: { value } }) => {
                setTitleEs(value);
              }}
            />
          </div>
          <div className="col">
            <small>*Título inglés</small>
            <input
              type="text"
              className="form-control"
              placeholder="Título inglés"
              value={titleEn}
              onChange={({ target: { value } }) => {
                setTitleEn(value);
              }}
            />
          </div>
          <div className="col">
            <small>*Título valenciano</small>
            <input
              type="text"
              className="form-control"
              placeholder="*Título valenciano"
              value={titleVa}
              onChange={({ target: { value } }) => {
                setTitleVa(value);
              }}
            />
          </div>
        </div>

        <div className="form-row mt-4">
          <div className="col">
            <small>*Tipo</small>
            <select
              className="form-control input"
              value={chosenCommunicationTypeId}
              onChange={({ target: { value } }) => {
                setChosenCommunicationTypeId(+value);
                cleanSubcategory();
              }}
            >
              <option value={""}>*Tipo</option>
              {communicationTypes?.map(({ id, nameEs }) => (
                <option key={id} value={+id}>
                  {nameEs}
                </option>
              ))}
            </select>
          </div>

          <div className="col">
            <small>*Fecha de publicación</small>
            <DatePicker
              className="form-control input"
              placeholderText="*Fecha"
              selected={date}
              onChange={(date) => setDate(date)}
              dateFormat="dd MMMM, yyyy"
              isClearable
              locale="es"
              dropdownMode="select"
              showMonthDropdown
              showYearDropdown
              adjustDateOnChange
            />
          </div>
        </div>

        <div className="form-row mt-4">
          <div className="col-6">
            <small>*Etiqueta</small>
            <select
              className="form-control input"
              value={tag}
              onChange={({ target: { value } }) => {
                setTag(value);
              }}
            >
              <option value="research">Investigación</option>
              <option value="education">Educación</option>
            </select>
          </div>
          <div className="col-6">
            <small>*Subcategoría</small>
            <select
              className="form-control input"
              value={subcategory}
              onChange={({ target: { value } }) => {
                setSubCategory(value);
              }}
            >
              {chosenCommunicationTypeId === 1 ? (
                <>
                  <option value={"other"}>No procede</option>
                  <option key={id} value="press_release">
                    Nota de prensa
                  </option>
                  <option key={id} value="outreach">
                    Divulgación
                  </option>{" "}
                </>
              ) : chosenCommunicationTypeId === 3 ? (
                <>
                  <option value={"other"}>No procede</option>
                  <option value="seminar">Seminario</option>
                  <option value="workshop">Talleres</option>
                  <option value="congress">Congreso</option>
                  <option value="thesis">Tesis</option>
                  <option value="other_events">Otros eventos</option>
                </>
              ) : chosenCommunicationTypeId === 2 ? (
                <>
                  <option value="other">No procede</option>
                </>
              ) : chosenCommunicationTypeId === 4 ? (
                <>
                  <option value="other">No procede</option>
                </>
              ) : (
                <>
                  <option value={null}>Seleccione un tipo de noticia</option>
                </>
              )}
            </select>
          </div>
        </div>

        {chosenCommunicationTypeId === 3 ? (
          <>
            <div className="">
              <small>Título del evento</small>
              <input
                type="text"
                className="form-control"
                placeholder="Título del evento"
                value={eventTitle}
                onChange={({ target: { value } }) => {
                  setEventTitle(value);
                }}
              />
            </div>
            <div className="form-row mt-2">
              <div className="col">
                <small>Fecha y hora del evento</small>
                <input
                  type="datetime-local"
                  className="form-control"
                  value={eventDateTime}
                  onChange={({ target: { value } }) => {
                    setEventDateTime(value);
                  }}
                />
              </div>
              <div className="col">
                <small>Presentador del evento</small>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Presentador"
                  value={eventPresenter}
                  onChange={({ target: { value } }) => {
                    setEventPresenter(value);
                  }}
                />
              </div>
              <div className="col">
                <small>Lugar del evento</small>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Lugar del evento"
                  value={eventVenue}
                  onChange={({ target: { value } }) => {
                    setEventVenue(value);
                  }}
                />
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        {(isCreate || allowedEdit) && (
          <>
            <div className="form-row mt-4">
              <div className="col">
                <small>*Texto en español</small>
                <CKEditor
                  initData={contentEs}
                  config={editorConfig}
                  onChange={({ editor }) => {
                    setContentEs(editor.getData());
                  }}
                />
              </div>
            </div>

            <div className="form-row mt-4">
              <div className="col">
                <small>*Texto en inglés</small>
                <CKEditor
                  initData={contentEn}
                  config={editorConfig}
                  onChange={({ editor }) => {
                    setContentEn(editor.getData());
                  }}
                />
              </div>
            </div>
            <div className="form-row mt-4">
              <div className="col">
                <small>*Texto en valenciano</small>
                <CKEditor
                  initData={contentVa}
                  config={editorConfig}
                  onChange={({ editor }) => {
                    setContentVa(editor.getData());
                  }}
                />
              </div>
            </div>
          </>
        )}

        <div className="form-row mt-4">
          <div className="col">
            <small>*Palabras clave español (posicionamiento SEO)</small>
            <input
              type="text"
              className="form-control"
              placeholder="*Por favor separe cada palabra por una coma (e.g. océano, mar, olas)"
              value={keywordsEs}
              onChange={({ target: { value } }) => {
                setKeywordsEs(value);
              }}
            />
          </div>
          <div className="col">
            <small>*Palabras clave inglès (posicionamiento SEO)</small>
            <input
              type="text"
              className="form-control"
              placeholder="*Por favor separe cada palabra por una coma (e.g. océano, mar, olas)"
              value={keywordsEn}
              onChange={({ target: { value } }) => {
                setKeywordsEn(value);
              }}
            />
          </div>
          <div className="col">
            <small>*Palabras clave valenciano(posicionamiento SEO)</small>
            <input
              type="text"
              className="form-control"
              placeholder="*Por favor separe cada palabra por una coma (e.g. océano, mar, olas)"
              value={keywordsVa}
              onChange={({ target: { value } }) => {
                setKeywordsVa(value);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
